<template>
  <div class="revenue-detail">
    <title-bar :title="pageTitle"></title-bar>
    <div class="detail-box">
      <div class="content-tab">
        <div
          class="tab-item"
          :class="{ active: tabActive === i }"
          v-for="(item, i) in tabItems"
          :key="i"
          @click="onClickItem(i)"
        >
          <div class="item-start" v-show="tabActive === i"></div>
          <div
            class="item-content"
            :class="{
              'left-border': tabActive !== i && i !== 0 && tabActive + 1 !== i,
              'right-border':
                tabActive !== i &&
                i !== tabItems.length - 1 &&
                tabActive - 1 !== i
            }"
          >
            <span :class="[item.icon]" :style="{ color: item.color }"></span>
            {{ item.val }}
          </div>
          <div class="item-end" v-show="tabActive === i"></div>
        </div>
      </div>
      <div class="detail-content">
        <ul class="detail-list">
          <li class="list-title">
            <div class="item item-street">街道</div>
            <div class="item item-parking">车场名</div>
            <div class="item item-rate">营收增长率</div>
          </li>
          <div class="list-scroll-box">
            <li
              class="list-item"
              v-for="(item, index) in showList"
              :key="index"
            >
              <div class="item item-street">{{ item.streetName }}</div>
              <div class="item item-parking">{{ item.parkingName }}</div>
              <div
                class="item item-rate"
                :class="{ up: tabActive === 0, down: tabActive === 1 }"
              >
                {{ item[pageKey] || "--" }}%
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    pageTitle: "",
    tabItems: [
      {
        key: "up",
        val: 0,
        icon: "icon-up",
        color: "#008000"
      },
      {
        key: "down",
        val: 0,
        icon: "icon-down",
        color: "#ff0000"
      },
      {
        key: "flat",
        val: 0,
        icon: "icon-flat",
        color: "#0b0ba2"
      }
    ],
    tabActive: 0,
    pageKey: "",

    data: {}
  }),
  computed: {
    showList() {
      switch (this.tabActive) {
        case 0:
          return this.data.parkingIncomeAnalysisList
            .filter(item => item[this.pageKey] > 0)
            .sort((a, b) => a[this.pageKey] - b[this.pageKey]);
        case 1:
          return this.data.parkingIncomeAnalysisList
            .filter(item => item[this.pageKey] < 0)
            .sort((a, b) => a[this.pageKey] - b[this.pageKey]);
        case 2:
          return this.data.parkingIncomeAnalysisList
            .filter(item => item[this.pageKey] === 0 || !item[this.pageKey])
            .sort((a, b) => a[this.pageKey] - b[this.pageKey]);

        default:
          return [];
      }
    }
  },
  methods: {
    onClickItem(i) {
      this.tabActive = i;
    }
  },

  created() {
    let { params } = this.$route;
    let { template, data } = params;

    this.data = data;
    this.pageTitle = template.label;
    this.pageKey = template.key;
    this.tabItems.forEach(item => (item.val = data[template[item["key"]]]));
    // this.tabItems.forEach(item => {
    //   item.val = query[item.key];
    // });

    console.log(this.data.parkingIncomeAnalysisList);
  }
};
</script>

<style lang="less" scoped>
.revenue-detail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  position: absolute;
  left: 0;
  top: 0;
}

.detail-box {
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  padding: 12px;
  overflow: hidden;
}

.content-tab {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 12px;

  .tab-item {
    width: 100px;
    height: 38px;
    padding: 8px 0;
    background-color: #f0f2f5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;

    &.active {
      background-color: #fff;
      z-index: 10;
    }

    .item-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      &.left-border {
        border-left: 1px solid #999;
      }

      &.right-border {
        border-right: 1px solid #999;
      }
    }

    .item-start,
    .item-end {
      width: 10px;
      height: 10px;
      position: absolute;
      bottom: 0;
      background-color: #fff;

      &::after {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        background-color: #f0f2f5;
      }
    }

    .item-start {
      left: -10px;

      &::after {
        border-bottom-right-radius: 100%;
      }
    }

    .item-end {
      right: -10px;

      &::after {
        border-bottom-left-radius: 100%;
      }
    }

    .icon-flat {
      display: block;
      width: 8px;
      height: 2px;
      margin-right: 2px;
      background-color: #0b0ba2;
    }
  }
}

.detail-content {
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.detail-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
  overflow: hidden;

  .item {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    color: #333;

    &.item-street {
      flex: 0 0 90px;
    }

    &.item-rate {
      flex: 0 0 88px;
    }

    &.up {
      color: #008000;
    }

    &.down {
      color: #ff0000;
    }
  }

  .list-title {
    width: 100%;
    height: 44px;
    background-color: #ccebff;
    display: flex;
  }

  .list-scroll-box {
    flex: 1;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .list-item {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 44px;
  }
}
</style>
